import React from 'react'
import classNames from 'classnames/bind'

import { Row, Column } from 'components/core/Grid'
import Spacer from 'components/core/Spacer'
import useTheme from 'lib/useTheme'
import useCopyContext from 'context/copy'
import { FullscreenGlobe } from 'components/ui/Globe'
import { SpinningButtonStories } from 'components/ui/SpinningButton'
import Container from 'components/core/Container'
import { MonumentHeading1, CircularBody, CircularCaption } from 'components/ui/Text'
import ScrollIndicator from 'components/ui/ScrollIndicator'
import Logo from 'assets/svg/undp_logo_inverted.react.svg'

import { AboutHeroProps } from './AboutHeroTypes'
import * as s from './AboutHero.module.scss'
const cn = classNames.bind(s)

const AboutHero = ({ title, description }: AboutHeroProps) => {
  useTheme('dark', 'white')
  const copy = useCopyContext(s => s.copy)

  return (
    <Container className={cn('container')} as='section'>
      <FullscreenGlobe />
      <Row>
        <Column initial={6} medium={1} />
        <Column className={cn('content')} initial={6} medium={4}>
          <Spacer initial='27.4vw' medium='6.4vw' />
          <Logo className={cn('logo')} />
          <CircularCaption className={cn('logoText')} as='p'>
            {copy.presents}
          </CircularCaption>
          <Spacer initial='15vw' medium='6.25vw' />
          <MonumentHeading1 className={cn('title')}>{title?.text || ''}</MonumentHeading1>
          {description && (
            <div className={cn('description')}>
              <Spacer initial='10vw' medium='1vw' />
              <CircularBody weight='book' className={cn('text')}>
                {description.text}
              </CircularBody>
            </div>
          )}
          <Spacer initial='13vw' hideOnDesktop />
          <SpinningButtonStories className={cn('videosCTA')} />
        </Column>
        <Column initial={6} medium={1} />
      </Row>
      <div className={cn('indicator')}>
        <ScrollIndicator to='#section-1' alignToTop absolute>
          {copy.scroll_text}
        </ScrollIndicator>
      </div>
    </Container>
  )
}

export default AboutHero
