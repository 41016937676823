import React from 'react'

import useTheme from 'lib/useTheme'
import useCopy from 'lib/useCopy'
import SEO from 'components/core/SEO'
import AppearAnimation from 'components/core/AppearAnimation'
import SliceRenderer from 'components/core/SliceRenderer'
import { AboutHero } from 'components/ui/Hero'

import { AboutPageTypes } from './AboutPageTypes'

const AboutPage = ({ data }: AboutPageTypes) => {
  useTheme('dark', 'white')
  useCopy(data.prismicSiteSettings?.data)

  if (!data) return null
  const document = data.prismicAboutPage

  return (
    <AppearAnimation>
      <>
        <SEO
          title={document.data.page_meta_title}
          description={document.data.page_meta_description}
          thumbnail={document.data.page_meta_thumbnail}
        />
        <AboutHero title={document.data.title} description={document.data.description} />
        <SliceRenderer slices={document.data.body} />
      </>
    </AppearAnimation>
  )
}

export default AboutPage
