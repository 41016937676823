import React from 'react'
import { motion } from 'framer-motion'

import { AppearAnimationProps } from './AppearAnimationTypes'
import defaultVariants from './variants'

const AppearAnimation = ({ children, variants }: AppearAnimationProps) => (
  <motion.div variants={variants || defaultVariants} initial='hidden' animate='visible'>
    {children}
  </motion.div>
)

export default AppearAnimation
