// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var container = "AboutHero-module--container--1LDSN";
export var logo = "AboutHero-module--logo--3Stwp";
export var logoText = "AboutHero-module--logoText--3gwmW";
export var title = "AboutHero-module--title--1zVL2";
export var text = "AboutHero-module--text--1YYVo";
export var description = "AboutHero-module--description--APFCl";
export var indicator = "AboutHero-module--indicator--2UtmP";
export var videosCTA = "AboutHero-module--videosCTA--8ucSn";