import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { linkResolver } from 'prismic/linkResolver'
import AboutPage from 'views/AboutPage'

import { prismicRepo } from '../../prismic-configuration'

export const query = graphql`
  query AboutPageQuery($lang: String) {
    prismicAboutPage(lang: { eq: $lang }) {
      _previewable
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        body {
          ... on PrismicAboutPageDataBodySteps {
            slice_type
            primary {
              steps_title {
                text
              }
            }
            items {
              step_link_label
              step_link {
                uid
                type
                lang
              }
              step_description {
                text
              }
              step_title {
                text
              }
              image {
                gatsbyImageData
                alt
              }
            }
          }
          ... on PrismicAboutPageDataBodyTextSection {
            slice_type
            primary {
              section_text {
                raw
              }
              section_title {
                text
              }
            }
          }
        }
        title {
          text
        }
        description {
          text
        }
        page_meta_title
        page_meta_description
        page_meta_thumbnail {
          url
          dimensions {
            height
            width
          }
          alt
        }
      }
    }
    prismicSiteSettings(lang: { eq: $lang }) {
      _previewable
      lang
      data {
        scroll_text
        presents
        footer_copyright
        footer_bottom_links {
          footer_bottom_link {
            uid
            type
            lang
          }
          footer_bottom_link_text
        }
        footer_description {
          raw
        }
        footer_logos {
          footer_logo_image {
            gatsbyImageData
            alt
          }
        }
        footer_social_links {
          footer_social_link_icon
          footer_social_link_text
          footer_social_link {
            url
          }
        }
      }
    }
  }
`

export default withPrismicPreview(AboutPage, [
  {
    repositoryName: prismicRepo,
    // @ts-ignore
    linkResolver,
  },
])
