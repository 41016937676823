import { easeQuadOut } from 'd3-ease'

const variants = {
  hidden: {
    opacity: 0,
    y: '5vh',
    transition: {
      duration: 0.45,
      ease: easeQuadOut,
    },
  },
  visible: {
    opacity: 1,
    y: '0vh',
    transition: {
      duration: 0.45,
      ease: easeQuadOut,
    },
  },
}

export default variants
